module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":10000,"linkImagesToOriginal":false,"withWebp":true,"disableBgImage":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog Gymlib","short_name":"Gymlib Blog","description":"Le blog de Gymlib vous parle de sport et de bien-être, mais aussi des bonnes pratiques RH en matière de qualité de vie au travail et de marque employeur.","lang":"fr","start_url":"/fr/","background_color":"#3C0CBF","theme_color":"#3C0CBF","display":"minimal-ui","icon":"src/images/favicon.svg","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/nl/","lang":"nl"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"507d2243046236cce58ab6c6937415a1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Accueil","exclude":["/","/dev-404-page","/fr/404","/nl/404","/en/404","/404","/404.html","/offline-plugin-app-shell-fallback"],"useClassNames":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MS5BSH3","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
