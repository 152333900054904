import React from "react"
//import Axios from "axios"
/*
 * The reason we use Global State instead of Component State is that
 * when the user clicks something on the main page and then clicks back,
 * we don't want to reset the user's scroll position. If we don't maintain
 * state, then we will "lose" some of the items when the user clicks
 * back and the state resets, which obviously resets scroll position as well.
 */
export const GlobalStateContext = React.createContext({
  cursor: 1 /* Which page infinite scroll should fetch next. */,
  useInfiniteScroll: false /* Fallback in case of error. */,
  searchableContent: [],
  searchedContent: [],
  items: [],
  isLoading: true,
  menuActif: false,
  // loadMore: () => {},
  loadPage: (n) => {},
  hasMore: () => {},
  isInitializing: () => {
    return true
  },
  updateState: () => {},
})

export class GlobalState extends React.Component {
  constructor(props) {
    super(props)

//    this.loadMore = this.loadMore.bind(this)
    this.loadPage = this.loadPage.bind(this)
    this.hasMore = this.hasMore.bind(this)
    this.updateState = this.updateState.bind(this)
    this.isInitializing = this.isInitializing.bind(this)

    this.state = {
      searchableContent: [],
      searchedContent: [],
      /*  items contains posts which should be rendered
       *  items is initialized to 1 page of results, in order to:
       *    1. render a page to users who have JS disabled
       *    2. render initial paint fast for all users
       *  the initial page depends on pageContext.currentPage (corresponds to a path like "/", "/2", "/3",...)
       */
      items: [],
      /*
       *  isLoading is used to avoid triggering multiple simultaenous loadings
       */
      isLoading: true,
      /*
       *  cursor represents next page which infinite scroll should fetch
       */
      cursor: 0,
      /*
       * useInfiniteScroll is used to fallback to pagination _on error_
       * note that the fallback to non JS users is not related to this
       */
      useInfiniteScroll: true,
      menuActif: false,
      isInitializing: this.isInitializing,
      updateState: this.updateState,
      hasMore: this.hasMore,
//      loadMore: this.loadMore,
      loadPage: this.loadPage,
    }
  }

  isInitializing = () => {
    return this.state.cursor === 1
  }

  updateState = mergeableStateObject => {
    this.setState(mergeableStateObject)
  }

  async componentDidMount() {
    const lang = window ? window.location.pathname : "/fr"
    //console.log("GlobalStateMount (" + lang + ") props=", this.props)
    //let res = await Axios.get("/searchIndex" + lang + "/searchableContent.json")
    this.setState(state => ({
      isLoading: false, // Allow triggering infinite scroll load
      //searchableContent: res.data,
      //searchedContent: res.data,
      cursor: 1,
    }))
  }

  // loadMore = () => {
  //   this.setState({ isLoading: true, error: undefined })
  //   this.setState(state => ({
  //     items: this.state.searchedContent.slice(0, (state.cursor + 1) * 9), // Add resulting post items to state.items
  //     cursor: state.cursor + 1, // Update which page should be fetched next
  //     isLoading: false, // Loading is complete so a new load can be triggered.
  //   }))
  // }

  loadPage = n => {
    this.setState(state => ({
      items: this.state.searchedContent.slice(9*(n-1), 9),
      cursor: n,
    }))
    console.log( "loadPage ", n ) //, " items=", this.state.items)
  }


  hasMore = pageContext => {
    return this.state.items.length < this.state.searchedContent.length
    //return (
    //this.state.cursor <= pageContext.numPages && this.state.useInfiniteScroll
    //)
  }

  render() {
    return (
      <GlobalStateContext.Provider value={this.state}>
        {this.props.children}
      </GlobalStateContext.Provider>
    )
  }
}
